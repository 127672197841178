<template>
  <div class="examinerManagement">
    <div class="flex-box">
      <!-- <div class="left"> -->
      <!-- <el-button type="success">批量下载</el-button> -->
      <!-- </div> -->
    </div>
    <el-table :data="termTable">
      <el-table-column label="序号"
                       width="60px"
                       type="index"
                       align="center"></el-table-column>
      <el-table-column label="名称"
                       prop="name"
                       align="center"></el-table-column>
      <el-table-column label="起止时间"
                       prop="year"
                       align="center"></el-table-column>
      <el-table-column label="上传人"
                       prop="operName"
                       align="center"></el-table-column>
      <el-table-column label="上传时间"
                       prop="operTime"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       width="180px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="seeCourseTime(scope.row)">查看
          </el-button>

        </template>
      </el-table-column>
    </el-table>

    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="queryTerm"></el-pagination>

  </div>
</template>

<script>
export default {
  name: "courseAndTimeManagement",
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      termTable: [],
    };
  },
  created() {
    // 查询学期
    this.queryTerm(1);
  },
  methods: {
    // 查询学期
    queryTerm(page) {
      const that = this;
      let data = {
        indexNo: page,
        rows: 10,
      };
      this.$postJson("/position/hour/querySchoolYear", data)
        .then((res) => {
          console.log(res);
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
          res.data.rows.forEach((item) => {
            item.name = item.year + " 学科课时表";
          });
          that.termTable = res.data.rows;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 查看
    seeCourseTime(row) {
      // 跳转到学科课时页面
      this.$router.push({
        name: "templateCourseTime",
        query: {
          termid: row.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
